import React from "react"

import t from "@translations"
import { Wrap, Inner, VideoWrap } from "./styled"
import Container from "@components/shared/Container"

const Banner = () => {
    return (
        <Wrap>
            <Container>
                <Inner>
                    <VideoWrap>
                        <h1>{t["banner-title"]}</h1>
                        <p>{t["banner-text"]}</p>
                        <Container>
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/JNrN9hCwzug?rel=0"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </Container>
                    </VideoWrap>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default Banner
