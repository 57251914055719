import styled from "styled-components"

import { colors, media } from "@variables"
import Bkg from "@static/images/banner.png"

export const Wrap = styled.section`
    width: 100%;
    background-color: ${colors.black};

    background-repeat: no-repeat;
    background-image: url(${Bkg});
    background-position: right bottom;

    padding: 80px 0 220px 0;
    background-size: 310px auto;

    ${media.phone} {
        background-size: 450px auto;
    }
    ${media.tablet} {
        padding: 100px 0 300px 0;
    }
    ${media.desktop} {
        padding: 100px 0 285px 0;
    }
`

export const Inner = styled.div`
    width: 100%;

    h1,
    p {
        color: white;
    }

    h1 {
        padding: 0 0 36px 0;
    }
    p {
        max-width: 660px;
        font-weight: 300;

        font-size: 20px;
        line-height: 28px;

        ${media.tablet} {
            font-size: 28px;
            line-height: 38px;
        }
    }
`

export const VideoWrap = styled.div`
    width: 100%;
    height: 315px;
    background-color: ${colors.black};
    
    p {
        margin: 15px;
    }

    .container {
        display: flex;
        height: 100%;
        justify-content: flex-start;
    }

    @media (max-width: 854px) {
        .container {
            justify-content: center;
            padding: 0;
            iframe {
                margin-right: 0;
            }
        }
    }
`
