import React from "react"

import t from "@translations"
import Container from "@components/shared/Container"
import { Wrap, Row, Col, Title, Text, Description } from "./styled"

const Usp = () => {
    return (
        <Wrap>
            <Container>
                <Row>
                    {Array.from({ length: 3 }).map((_, idx) => (
                        <Col key={t[`usp-row0-item${idx}-title`]}>
                            <Title>{t[`usp-row0-item${idx}-title`]}</Title>
                            <Text>{t[`usp-row0-item${idx}-text`]}</Text>
                        </Col>
                    ))}
                </Row>
                <Row>
                    {Array.from({ length: 3 }).map((_, idx) => (
                        <Col key={t[`usp-row1-item${idx}-title`]}>
                            <Title>{t[`usp-row1-item${idx}-title`]}</Title>
                            <Text>{t[`usp-row1-item${idx}-text`]}</Text>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Wrap>
    )
}

export default Usp
