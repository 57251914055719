import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Keyboard, Autoplay } from "swiper"

import t from "@translations"
import { Slide, Title, Text, Wrap, ImgWrap, SlideInner } from "./styled"

SwiperCore.use([Keyboard, Autoplay, Mousewheel])

const Carousel = () => {
    return (
        <Wrap>
            <Swiper loop autoplay keyboard mousewheel slidesPerView="auto">
                <SwiperSlide
                    key={`0-${t[`carousel-item0-title`]}`}
                >
                    <Slide>
                        <ImgWrap>
                            <StaticImage
                                src="../../../static/images/octopus.svg"
                                alt={t[`carousel-item0-title`]}
                                layout="fixed"
                                loading="lazy"
                                height={120}
                                width={120}
                            />
                        </ImgWrap>
                        <SlideInner>
                            <Title>{t[`carousel-item0-title`]}</Title>
                            <Text className="body2">
                                {t[`carousel-item0-text`]}
                            </Text>
                        </SlideInner>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide
                    key={`1-${t[`carousel-item1-title`]}`}
                >
                    <Slide>
                        <ImgWrap>
                            <StaticImage
                                src="../../../static/images/octopus.svg"
                                alt={t[`carousel-item1-title`]}
                                layout="fixed"
                                loading="lazy"
                                height={120}
                                width={120}
                            />
                        </ImgWrap>
                        <SlideInner>
                            <Title>{t[`carousel-item1-title`]}</Title>
                            <Text className="body2">
                                {t[`carousel-item1-text`]}
                            </Text>
                        </SlideInner>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide
                    key={`2-${t[`carousel-item2-title`]}`}
                >
                    <Slide>
                        <ImgWrap>
                            <StaticImage
                                src="../../../static/images/octopus.svg"
                                alt={t[`carousel-item2-title`]}
                                layout="fixed"
                                loading="lazy"
                                height={120}
                                width={120}
                            />
                        </ImgWrap>
                        <SlideInner>
                            <Title>{t[`carousel-item2-title`]}</Title>
                            <Text className="body2">
                                {t[`carousel-item2-text`]}
                            </Text>
                        </SlideInner>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide
                    key={`3-${t[`carousel-item3-title`]}`}
                >
                    <Slide>
                        <ImgWrap>
                            <StaticImage
                                src="../../../static/images/octopus.svg"
                                alt={t[`carousel-item3-title`]}
                                layout="fixed"
                                loading="lazy"
                                height={120}
                                width={120}
                            />
                        </ImgWrap>
                        <SlideInner>
                            <Title>{t[`carousel-item3-title`]}</Title>
                            <Text className="body2">
                                {t[`carousel-item3-text`]}
                            </Text>
                        </SlideInner>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide
                    key={`4-${t[`carousel-item4-title`]}`}
                >
                    <Slide>
                        <ImgWrap>
                            <StaticImage
                                src="../../../static/images/octopus.svg"
                                alt={t[`carousel-item4-title`]}
                                layout="fixed"
                                loading="lazy"
                                height={120}
                                width={120}
                            />
                        </ImgWrap>
                        <SlideInner>
                            <Title>{t[`carousel-item4-title`]}</Title>
                            <Text className="body2">
                                {t[`carousel-item4-text`]}
                            </Text>
                        </SlideInner>
                    </Slide>
                </SwiperSlide>
            </Swiper>
        </Wrap>
    )
}

export default Carousel
