import * as React from "react"

import t from "@translations"
import Seo from "@components/seo"
import Layout from "@components/layout"
import Hero from "@sections/Hero"
// import Stats from "@sections/Stats"
import Carousel from "@sections/Carousel"
import About from "@sections/About"
import Usp from "@sections/Usp"
import Banner from "@sections/Banner"
import Strike from "@sections/Strike"

type Props = {
    location: Location
}

const Home = ({ location }: Props) => {
    return (
        <Layout location={location}>
            <Seo title={t["seo-title"]} />
            {/* <Hero /> */}
            {/* <Stats /> */}
            <About />
            <Carousel />
            <Usp />
            <Banner />
            <Strike />
        </Layout>
    )
}

export default Home
