import styled from "styled-components"

import Bkg from "@static/images/about.png"
import { colors, media } from "@variables"

export const Wrap = styled.section`
    width: 100%;
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-image: url(${Bkg});

    border-bottom: 1px solid ${colors.withVariableOpacity(colors.black, 0.08)};

    background-size: 95% auto;
    padding: 80px 15px 340px 15px;
    background-position: 100% 90%;

    ${media.phone} {
        background-size: 449px auto;
    }
    ${media.tablet} {
        padding: 150px 15px 270px 15px;
    }
`

export const Inner = styled.div`
    width: 100%;
    max-width: 580px;

    p {
        font-size: 20px;
        line-height: 28px;
        padding: 24px 0 36px 0;

        ${media.tablet} {
            font-size: 24px;
            line-height: 32px;
            padding: 36px 0 64px 0;
        }
    }
`
