import styled, { css } from "styled-components"

import { colors, media, transition } from "@variables"

export const Wrap = styled.section`
    width: 100%;
    position: relative;
    background-color: ${colors.withVariableOpacity(colors.red, 0.03)};

    padding: 80px 0 100px 0;

    ${media.tablet} {
        padding: 220px 0 240px 0;
    }

    .swiper-wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .swiper-slide {
        width: 100%;
        padding: 0 17px;
        max-width: 920px;
        white-space: pre-wrap;
        box-sizing: border-box;
    }
`

export const Slide = styled.div`
    display: flex;
    flex-direction: column;
`

export const SlideInner = styled.div`
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        max-width: 720px;
        padding-left: 100px;
    }
`

export const ImgWrap = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.red};
`

export const Title = styled.h4`
    padding: 44px 0 24px 0;
`

export const Text = styled.p`
    color: ${colors.withVariableOpacity(colors.black, 0.4)};
`
