import styled from "styled-components"

import { colors, media } from "@variables"

export const Wrap = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.withVariableOpacity(colors.black, 0.08)};

    padding: 80px 0;

    .container {
        max-width: 1100px;
    }

    ${media.tablet} {
        padding: 160px 0 100px 0;
    }
`

export const Inner = styled.div`
    max-width: 720px;

    h2 {
        padding-bottom: 24px;
    }

    p {
        :not(:last-of-type) {
            padding-bottom: 20px;
        }
    }
`
