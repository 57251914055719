import useUIStateValue from "@hooks/useUIStateValue"
import React, {useContext} from "react"

import t from "@translations"
import Button from "@components/shared/Button"
import { Wrap, Inner, Container } from "./styled"
import {BotContext} from "../../context/Bot";

const About = () => {
    const [open, setOpen] = useUIStateValue("isBotOpen")
    const { log, start } = useContext(BotContext)

    const toggleOpen = () => {
        setOpen(!open)
        if (log.length === 0) start()
    }

    return (
        <Wrap>
            <Container>
                <Inner>
                    <h1>{t["about-title"]}</h1>
                    <p>{t["about-text"]}</p>
                    <Button onClick={toggleOpen}>
                        {t["about-button"]}
                    </Button>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default About
