import React from "react"

import t from "@translations"
import { Wrap, Inner } from "./styled"
import Container from "@components/shared/Container"

const Strike = () => {
    return (
        <Wrap>
            <Container>
                <Inner>
                    <h2>{t["strike-title"]}</h2>
                    <p className="body1">{t["strike-text1"]}</p>
                    <p className="body1">{t["strike-text2"]}</p>
                    <p className="body1">{t["strike-text3"]}</p>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default Strike
