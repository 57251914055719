import styled from "styled-components"

import { colors, media } from "@variables"

export const Wrap = styled.section`
    width: 100%;
    padding: 80px 0;

    ${media.desktop} {
        padding: 140px 0 200px 0;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex: 0 0 100%;
    margin: 0 0 40px 0;
    flex-direction: column;

    ${media.desktop} {
        margin: 0;
        flex: 0 0 30%;
        max-width: 344px;
    }
`

export const Title = styled.h3`
    padding: 0 0 16px 0;

    ${media.desktop} {
        padding: 0 0 24px 0;
    }
`

export const Text = styled.p`
    font-size: 16px;
    line-height: 22px;
    max-width: 340px;

    ${media.desktop} {
        font-size: 18px;
        line-height: 26px;
    }
`

export const Description = styled.p`
    font-size: 16px;
    line-height: 22px;
    
    ${media.desktop} {
        font-size: 18px;
        line-height: 26px;
    }
`
